import breakpoints from '@/shared/json-styles/breakpoints.json'

const sizeUp = (key) => {
  return (style) => `@media (min-width: ${breakpoints[key].start}) { ${style} }`
}

const sizeDown = (key) => {
  return (style) => `@media (max-width: ${breakpoints[key].end}) { ${style} }`
}

const sizeOnly = (key) => {
  return (style) => `@media (min-width: ${breakpoints[key].start}) and (max-width: ${breakpoints[key].end}) { ${style} }`
}

const sizeIncludes = (key1, key2) => {
  return (style) => `@media (min-width: ${breakpoints[key1].start}) and (max-width: ${breakpoints[key2].end}) { ${style} }`
}

const isMobile = window.innerWidth <= breakpoints.mobile.end.split('px')[0]

// 1. isMobile above is actually checking for mobile and down
// 2. this function allows us to check the breakpoint dynamically without changing code that relies on the one above
const mobileAndDown = () => {
  return window.innerWidth <= breakpoints.mobile.end.split('px')[0]
}

const isTablet = window.innerWidth <= breakpoints.tablet.end.split('px')[0]

// 1. isTablet above is actually checking for tablet and down
// 2. this function allows us to check the breakpoint dynamically without changing code that relies on the one above
const tabletAndDown = () => {
  return window.innerWidth <= breakpoints.tablet.end.split('px')[0]
}

export { sizeUp, sizeDown, sizeOnly, sizeIncludes, breakpoints, isMobile, mobileAndDown, isTablet, tabletAndDown }
